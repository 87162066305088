import React, {useContext} from "react"

import Layout from "../components/layout"
import {Link, navigate} from "gatsby";
import {Formik} from "formik";
import {UserContext} from "../context/UserContext";
import {userPool} from "../utils/cognito-utils";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import SEO from "../components/seo";

const Login = ({location}) => {
  const userContext = useContext(UserContext);

  return (
    <Layout>
      <SEO title="Login" keywords={[`gatsby`, `application`, `react`]}/>
      <div className="font-title text-xl uppercase mt-16 mb-6 text-center tracking-wide">Login</div>
      <Formik
        initialValues={{email: '', password: ''}}
        validateOnBlur={false}
        validate={values => {
          let errors = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }
          if (!values.password) {
            errors.password = 'Required';
          }
          return errors;
        }}
        onSubmit={async (input, {setSubmitting, setStatus}) => {
          const {email, password} = input
          var userData = {
            Username: email,
            Pool: userPool
          };
          const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
          cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH')
          cognitoUser.authenticateUser(
            new AmazonCognitoIdentity.AuthenticationDetails({
              Username: email,
              Password: password,
            }),
            {
              onSuccess: function (result) {
                userContext.setUser(result)
                navigate((location.state && location.state.redirect) || '/myaccount/loginwelcome')
              },
              onFailure: function (err) {
                setStatus({msg: err.message});
              },
            });
          setSubmitting(false);
        }}
      >
        {({
            values,
            errors,
            touched,
            status,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
          <form className='w-full max-w flex flex-col items-center content-center pb-2 px-2 md:px-32 lg:px-64'
                onSubmit={handleSubmit}>
            <div className="mb-4 w-full">
              <input
                className='focus:outline-none w-full border-solid border-2 border-black p-2'
                type="email"
                name="email"
                placeholder='Email'
                autoFocus={true}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <p className="text-red-700 text-xs italic">
                {errors.email && touched.email && errors.email}
              </p>
            </div>
            <div className="mb-4 w-full">
              <input
                className='focus:outline-none w-full border-solid border-2 border-black p-2'
                type="password"
                name="password"
                placeholder='Password'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              <p className="text-red-700 text-xs italic">
                {errors.password && touched.password && errors.password}
              </p>
            </div>
            <p className="text-red-700 text-xs italic">
              {status && status.msg}
            </p>
            <button
              className='w-full bg-black font-title tracking-wide text-white py-3'
              type="submit" disabled={isSubmitting}>
              Sign in
            </button>
          </form>
        )}
      </Formik>
      <div className='flex flex-col w-full justify-center items-center mb-32'>
        <Link to='/register/' className='font-sans font-light'>Create account</Link>
        <Link to='/resetpassword/' className='font-sans font-light'>Reset password</Link>
      </div>
    </Layout>
  );
}

export default Login
